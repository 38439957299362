import React, {useState, useRef} from 'react'
import { Link } from 'react-router-dom'

const Home = () => {

	return (
		<>
			<div className="bg-home bg-image" id='home'>
				<div className='py-3'></div>
				<div className='row'>
					<div className='col-sm-3 col-1'></div>
					<div className='col-sm-6 col-10'>
						<div className="container my-4 mb-5 py-3 shade-box">						
							<div className="row d-flex justify-content-center text-center magic-text">
								<h3>No Such Thing as</h3>
							</div>
							<div className="row d-flex justify-content-center magic-text pb-4">
								<h3><i>Magic</i></h3>
							</div>
							<div className="row d-flex justify-content-center text-center py-3 mx-auto">
								<h4 className='px-5 fantasy-text'>Welcome to the fantasy world of <strong>J.A. Thurlow</strong></h4>
							</div>			
						</div>
					</div>
					<div className='col-sm-3 col-1'></div>
				</div>
				<div className='py-5' id='work'></div>
				<a href='/home#work'>
					<div className='row'>
						<div className='col-1'></div>
						<div className='col-10'>
							<div className='container mx-auto px-1 work-section'>
											
								<div className='row' style={{height: '680px'}}>
									<h3 className='work-header'><i>Upcoming novel</i></h3>
									<div className='work-text'>
										<div className='shade-box'>
											<p>Abuse of a mysterious drug is on the rise in the city of Berdonl. When Moiron Sagekin is called upon to unravel those mysteries, her deepest fears seem unavoidable. She wants no part of it, reminded of the judgment and ridicule that excessive attention brought her as an academic prodigy in her youth. Success in the project will likely bring her fame, even more people will know her name, and Moiron Sagekin does not fail in the laboratory.</p>
											<p>As Moiron surges forward with the study, considering ways she might give someone else the credit and attention, her path leads to betrayals and conspiracies deeper than she could have ever predicted. What new laws will arise from her results? Who will suffer, and who will benefit? Treachery seems to arise around every corner.</p>
											<p>Joined along the way by diverse companions including her warrior sister, a kind-hearted homeless man with an enigmatic background, and peculiar creatures from far away lands, Moiron's journey takes her to fantastic places beyond her dreams, and prejudices out of the darkest of nightmares. Is the political system she is part of far more flawed than she was raised to believe?</p>
											<p>An unforgettable adventure of mystery, betrayal, and the power of knowledge. Join Moiron on her epic quest of discovery and philanthropy.</p>
										</div>
										<div className='py-4'></div>
										<h4 className='shade-box my-5 py-1'>
											<Link className="fantasy-text bottom-text" to='/contact'>Join the Adventure</Link>
										</h4>
									</div>
									<div className='col-lg-4 col-md-12'>
										<img src={require('../images/masonBW.png')} alt='Mason Sagekin' className='primary-image-fit' style={{position: 'absolute', bottom: 0}}/>
										<h5 className='mason-text'><i>Mason Sagekin</i></h5>
									</div>
									<div className='col-lg-4 .d-lg-none .d-xl-block work-images'></div>
									<div className='col-lg-3 .d-lg-none .d-xl-block work-images'>
										<img src={require('../images/shield.png')} alt='Mason Sagekin' style={{position: 'absolute', bottom: 0}}/>
									</div>
									<div className='col-lg-1 .d-lg-none .d-xl-block work-images'></div>								

								</div>
							</div>
						</div>
					
						<div className='col-1'></div>
					</div>
					{/* <div className='row'>
						<div className='col-1'></div>
						<div className='col-10'>
							<h4 className="d-flex justify-content-center fantasy-text">
								<span className="bottom-text" href='/contact'>Join the Adventure</span>
							</h4>
						</div>
						<div className='col-1'></div>
					</div> */}
				</a>
			</div>
		</>
	)
}

export default Home